
import React, { Component } from 'react'

export default class Results extends Component {
  render() {
    return (
      <div id="availableDomains"></div>
    )
  }
}
